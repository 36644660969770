$COL1: rgba(0,0,0,0); //
$COLFLAT1: #808080; //
$COL2: #ffffff; //
$COLFLAT2: #ffffff; //
$COL3: #00adee; //
$COLFLAT3: #00adee; //
$COL4: #f26c3d; //
$COLFLAT4: #f26c3d; //
$COL5: #191919; //
$COLFLAT5: #191919; //
$COL6: #191919; //
$COLFLAT6: #191919; //
$COL7: #ffffff; //
$COLFLAT7: #ffffff; //
$COL8: #25408e; //
$COLFLAT8: #25408e; //
$COL9: #25408e; //
$COLFLAT9: #25408e; //
$COL10: #f26c3d; //
$COLFLAT10: #f26c3d; //
$COL11: #666666; //
$COLFLAT11: #666666; //
$COL12: #ffffff; //
$COLFLAT12: #ffffff; //
$breakpoints: (
  small: 0,
  medium: 640px,
  large: 1024px,
  xlarge: 1248px,
  xxlarge: 1936px,
);
$global-width: 1200px;
