body { background-color: $COL2; }
body { color: $COL6; }
[data-pad="0"] { margin-top: 20px; }

[data-pad="1"] { margin-top: 5px; }

h1 {
color: $COL9;
font-family: 'Oswald';
font-weight: 700;
font-style: normal;
line-height: 1.2;
text-transform: uppercase;
letter-spacing: 0;
font-size: 25px;

@media #{$medium-up} {
font-size: 30px;

}
@media #{$large-up} {
font-size: 50px;

}
}
h2 {
font-family: 'Oswald';
font-weight: 600;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
letter-spacing: 0;
font-size: 22px;

@media #{$medium-up} {
font-size: 30px;

}
@media #{$large-up} {
font-size: 40px;

}
}
h3 {
color: $COL10;
font-family: 'Oswald';
font-weight: 600;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
letter-spacing: 0;
font-size: 20px;

@media #{$medium-up} {
font-size: 30px;

}
@media #{$large-up} {
font-size: 35px;

}
}
h4 {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 18px;

@media #{$medium-up} {
font-size: 25px;

}
}
h5 {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 17px;

@media #{$medium-up} {
font-size: 20px;

}
}
h6 {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 16px;

@media #{$medium-up} {
font-size: 18px;

}
}
.button {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
.me-Quote .quote-body {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
.me-Quote .quote-author {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
ol,ul {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
ol,ul { margin-bottom: 1em; list-style-position: outside; }
ol { margin-left: 1.25em; list-style-type: decimal; }
ul { margin-left: 1.25em; list-style-type: disc; }
ul, ol { & & { margin-bottom: 0 } }
cite {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
summary {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.mainmenu > .menu-item > a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.mainmenu > .menu-item > div > a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.secondarymenu > .menu-item > a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.me-RespMenu.responsive-menu a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 20px;

}
body {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
p { padding-bottom: 1em; }
/* Primary:2 */
.MES2 {
background-color: $COL3;
color: $COL7;
 }
/* Primary:3 */
.MES3 {
background-color: $COL3;
color: $COL7;
 }
.MES3 {
background-color: $COL3;
color: $COL7;
h1.MEC3, h2.MEC3, h3.MEC3, h4.MEC3, h5.MEC3, h6.MEC3 { color: $COL7;
 }
 }
cite.MEC3{
color: $COL7;
}
/* Secondary:4 */
.MES4 {
background-color: $COL4;
 }
/* Secondary:5 */
.MES5 {
background-color: $COL4;
 }
.MES5 {
background-color: $COL4;
 }
/* Alternate:6 */
.MES6 {
background-color: $COL5;
color: $COL12;
 }
/* Alternate:7 */
.MES7 {
background-color: $COL5;
color: $COL12;
 }
.MES7 {
background-color: $COL5;
color: $COL12;
h1.MEC7, h2.MEC7, h3.MEC7, h4.MEC7, h5.MEC7, h6.MEC7 { color: $COL12;
 }
 }
cite.MEC7{
color: $COL12;
}
/* Bg image:8 */
.MES8 {
& > .underlay, & > .inner-overlay { opacity: 0.9999 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://bettermentalhealth.org.au/img/56/11');
background-position: top center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: $COL2;
@media #{$medium-up} {
min-height: 400px;};
@media #{$large-up} {
min-height: 400px;};
 }
.MES8 {
& > .underlay, & > .inner-overlay { opacity: 0.9999 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://bettermentalhealth.org.au/img/56/11');
background-position: top center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: $COL2;
@media #{$medium-up} {
min-height: 400px;};
@media #{$large-up} {
min-height: 400px;};
h1.MEC8, h2.MEC8, h3.MEC8, h4.MEC8, h5.MEC8, h6.MEC8 { color: $COL2;
 }
 }
cite.MEC8{
color: $COL2;
}
/* Dark:9 */
.MES9 {
background-color: $COL8;
 }
/* Dark:10 */
.MES10 {
background-color: $COL8;
 }
.MES10 {
background-color: $COL8;
 }
/* HACSU link:11 */
.MES11 {
color: $COL8;
 }
.MES11 {
color: $COL8;
h1.MEC11, h2.MEC11, h3.MEC11, h4.MEC11, h5.MEC11, h6.MEC11 { color: $COL8;
 }
 }
a.MEC11 { color: $COL8;
 }
cite.MEC11{
color: $COL8;
}
/* Secondary:12 */
.MES12 {
background-color: $COL4;
&:hover { background-color: $COL11;}
color: $COL2;
font-size: 16.8px;
@media #{$medium-up} {
font-size: 22.4px;
};
@media #{$large-up} {
font-size: 24px;
};
padding: 15px;

@media #{$medium-up} {
padding: 30px 10px;

}
@media #{$large-up} {
padding: 40px;

}
 }
/* Blue Button:13 */
.MES13 {
background-color: $COL3;
&:hover { background-color: $COL11;}
color: $COL2;
font-size: 16.8px;
@media #{$medium-up} {
font-size: 22.4px;
};
@media #{$large-up} {
font-size: 24px;
};
padding: 15px;

@media #{$medium-up} {
padding: 30px;

}
@media #{$large-up} {
padding: 40px;

}
 }
/* Secondary:14 */
.MES14 {
background-color: $COL8;
&:hover { background-color: $COL11;}
color: $COL2;
font-size: 16.8px;
@media #{$medium-up} {
font-size: 22.4px;
};
@media #{$large-up} {
font-size: 24px;
};
padding: 15px;

@media #{$medium-up} {
padding: 30px 10px;

}
@media #{$large-up} {
padding: 40px;

}
 }
/* Black Button:15 */
.MES15 {
background-color: $COL5;
&:hover { background-color: $COL11;}
color: $COL2;
font-size: 16.8px;
@media #{$medium-up} {
font-size: 22.4px;
};
@media #{$large-up} {
font-size: 24px;
};
padding: 15px;

@media #{$medium-up} {
padding: 30px;

}
@media #{$large-up} {
padding: 40px;

}
 }
